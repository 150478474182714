import Vue from "vue";
import VueRouter from "vue-router";
import Start from "@/views/Start";
import Eind from "@/views/Eind";
import EindB from "@/views/EindB";
const startA = () =>
  import("../views/ascan/startLandingA" /* webpackChunkName: "startA" */);
const styleA = () =>
  import("../views/ascan/styleScanA" /* webpackChunkName: "styleA" */);
const introA = () =>
  import("../views/ascan/introScanA" /* webpackChunkName: "introA" */);
const indexA = () =>
  import("../views/ascan/scanIndexA" /* webpackChunkName: "indexA" */);

const startB = () =>
  import("../views/bscan/startLandingB" /* webpackChunkName: "startB" */);
const styleB = () =>
  import("../views/bscan/styleScanB" /* webpackChunkName: "styleB" */);
const introB = () =>
  import("../views/bscan/introScanB" /* webpackChunkName: "introB" */);
const indexB = () =>
  import("../views/bscan/scanIndexB" /* webpackChunkName: "indexB" */);

const startC = () =>
  import("../views/cscan/startLandingC" /* webpackChunkName: "startC" */);
const styleC = () =>
  import("../views/cscan/styleScanC" /* webpackChunkName: "styleC" */);
const introC = () =>
  import("../views/cscan/introScanC" /* webpackChunkName: "introC" */);
const indexC = () =>
  import("../views/cscan/scanIndexC" /* webpackChunkName: "indexC" */);

const startD = () =>
  import("../views/dscan/startLandingD" /* webpackChunkName: "startD" */);
const styleD = () =>
  import("../views/dscan/styleScanD" /* webpackChunkName: "styleD" */);
const introD = () =>
  import("../views/dscan/introScanD" /* webpackChunkName: "introD" */);
const indexD = () =>
  import("../views/dscan/scanIndexD" /* webpackChunkName: "indexD" */);

const startE = () =>
  import("../views/escan/startLandingE" /* webpackChunkName: "startE" */);
const styleE = () =>
  import("../views/escan/styleScanE" /* webpackChunkName: "styleE" */);
const introE = () =>
  import("../views/escan/introScanE" /* webpackChunkName: "introE" */);
const indexE = () =>
  import("../views/escan/scanIndexE" /* webpackChunkName: "indexE" */);

const startH = () =>
  import("../views/hscan/startLandingH" /* webpackChunkName: "startH" */);
const styleH = () =>
  import("../views/hscan/styleScanH" /* webpackChunkName: "styleH" */);
const introH = () =>
  import("../views/hscan/introScanH" /* webpackChunkName: "introH" */);
const indexH = () =>
  import("../views/hscan/scanIndexH" /* webpackChunkName: "indexH" */);

const startI = () =>
  import("../views/iscan/startLandingI" /* webpackChunkName: "startI" */);
const styleI = () =>
  import("../views/iscan/styleScanI" /* webpackChunkName: "styleI" */);
const introI = () =>
  import("../views/iscan/introScanI" /* webpackChunkName: "introI" */);
const indexI = () =>
  import("../views/iscan/scanIndexI" /* webpackChunkName: "indexI" */);

const startJ = () =>
  import("../views/jscan/startLandingJ" /* webpackChunkName: "startJ" */);
const styleJ = () =>
  import("../views/jscan/styleScanJ" /* webpackChunkName: "styleJ" */);
const introJ = () =>
  import("../views/jscan/introScanJ" /* webpackChunkName: "introJ" */);
const indexJ = () =>
  import("../views/jscan/scanIndexJ" /* webpackChunkName: "indexJ" */);

const startK = () =>
  import("../views/kscan/startLandingK" /* webpackChunkName: "startK" */);
const styleK = () =>
  import("../views/kscan/styleScanK" /* webpackChunkName: "styleK" */);
const introK = () =>
  import("../views/kscan/introScanK" /* webpackChunkName: "introK" */);
const indexK = () =>
  import("../views/kscan/scanIndexK" /* webpackChunkName: "indexK" */);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/noodopvolging",
      name: "start_a",
      component: startA,
    },
    {
      path: "/stijl-noodopvolging",
      name: "style_a",
      component: styleA,
    },
    {
      path: "/quickscan-noodopvolging",
      name: "index_a",
      component: indexA,
    },
    {
      path: "/intro-noodopvolging",
      name: "intro_a",
      component: introA,
    },

    {
      path: "/familiestatuut",
      name: "start_b",
      component: startB,
    },
    {
      path: "/stijl-familiestatuut",
      name: "style_b",
      component: styleB,
    },
    {
      path: "/quickscan-familiestatuut",
      name: "index_b",
      component: indexB,
    },
    {
      path: "/intro-familiestatuut",
      name: "intro_b",
      component: introB,
    },

    {
      path: "/basis",
      name: "start_c",
      component: startC,
    },
    {
      path: "/stijl-basis",
      name: "style_c",
      component: styleC,
    },
    {
      path: "/quickscan-basis",
      name: "index_c",
      component: indexC,
    },
    {
      path: "/intro-basis",
      name: "intro_c",
      component: introC,
    },
    {
      path: "/grip",
      name: "start_d",
      component: startD,
    },
    {
      path: "/stijl-grip",
      name: "style_d",
      component: styleD,
    },
    {
      path: "/quickscan-grip",
      name: "index_d",
      component: indexD,
    },
    {
      path: "/intro-grip",
      name: "intro_d",
      component: introD,
    },
    {
      path: "/juridisch",
      name: "start_e",
      component: startE,
    },
    {
      path: "/stijl-juridisch",
      name: "style_e",
      component: styleE,
    },
    {
      path: "/quickscan-juridisch",
      name: "index_e",
      component: indexE,
    },
    {
      path: "/intro-juridisch",
      name: "intro_e",
      component: introE,
    },
    {
      path: "/exit-planning",
      name: "start_h",
      component: startH,
    },
    {
      path: "/stijl-exit-planning",
      name: "style_h",
      component: styleH,
    },
    {
      path: "/quickscan-exit-planning",
      name: "index_h",
      component: indexH,
    },
    {
      path: "/intro-exit-planning",
      name: "intro_h",
      component: introH,
    },
    {
      path: "/cyber-security",
      name: "start_i",
      component: startI,
    },
    {
      path: "/stijl-cyber-security",
      name: "style_i",
      component: styleI,
    },
    {
      path: "/quickscan-cyber-security",
      name: "index_i",
      component: indexI,
    },
    {
      path: "/intro-cyber-security",
      name: "intro_i",
      component: introI,
    },
    {
      path: "/digitale-strategie",
      name: "start_j",
      component: startJ,
    },
    {
      path: "/stijl-digitale-strategie",
      name: "style_j",
      component: styleJ,
    },
    {
      path: "/quickscan-digitale-strategie",
      name: "index_j",
      component: indexJ,
    },
    {
      path: "/intro-digitale-strategie",
      name: "intro_j",
      component: introJ,
    },
    {
      path: "/prive-situatie",
      name: "start_k",
      component: startK,
    },
    {
      path: "/stijl-prive-situatie",
      name: "style_k",
      component: styleK,
    },
    {
      path: "/quickscan-prive-situatie",
      name: "index_k",
      component: indexK,
    },
    {
      path: "/intro-prive-situatie",
      name: "intro_k",
      component: introK,
    },
    {
      path: "/",
      name: "Start",
      component: Start,
    },
    {
      path: "/uitgelogd",
      name: "Eind",
      component: Eind,
    },
    {
      path: "/jammer",
      name: "EindB",
      component: EindB,
    },
    {
      path: "*",
      component: Start,
      name: "Start",
    },
  ],
});

export default router;
